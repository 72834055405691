/* eslint-disable no-underscore-dangle */
/* eslint-disable react/jsx-props-no-spreading */
import * as localStorage from 'local-storage';
import App from 'next/app';
import Cookies from 'js-cookie';
import Head from 'next/head';
import PropTypes from 'prop-types';
import React, { ReactElement, useEffect, useState } from 'react';
import { IntlProvider } from 'react-intl';
import { Provider } from 'react-redux';
import { parse } from '@formatjs/icu-messageformat-parser';
import { useRouter } from 'next/router';
import getConfig from 'next/config';

import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone'; // depends on utc plugin
import utc from 'dayjs/plugin/utc';

import { ThemeProvider, Emotion } from 'groundkeeper-component-library';

import { customization, theme } from 'shared/App/utils/Theme';
import Globals from 'shared/App/utils/Globals';
import featureToggles from 'shared/Redux/utils/featureToggles';
import isClient from 'shared/Redux/utils/isClient';
import { fetchOrganizations } from 'shared/Redux/slices/organization.slice';
import { fetchUser } from 'shared/Redux/slices/auth.slice';
import { hasSessionCookie } from 'shared/Redux/api/withAuthentication';
import { setToggles } from 'shared/Redux/slices/feature.slice';
import { wrapper } from 'shared/Redux/store';
import {
  historyPush,
  initialized
} from 'shared/Redux/slices/application.slice';

dayjs.extend(utc);
dayjs.extend(timezone);

const env = getConfig()?.publicRuntimeConfig;
const settings = Globals.settings;

// Client-side cache, shared for the whole session of the user in the browser.
const clientSideEmotionCache = Emotion.createCache();
function RMApp({
  Component,
  pageProps,
  emotionCache = clientSideEmotionCache,
  intlMessages,
  ...rest
}) {
  const getLayout = Component.getLayout || ((page: ReactElement) => page);
  const router = useRouter();
  const { store, props } = wrapper.useWrappedStore({
    pageProps,
    emotionCache,
    ...rest
  });
  const [clientSide, setClientSide] = useState(false);

  // Save client's current timezone in a cookie.
  // The cookie is used in store.ts to set timezone as a default header for axios requests.
  if (isClient) {
    try {
      Cookies.set('timezone', dayjs.tz.guess(), {
        sameSite: 'lax',
        secure: window.location.protocol === 'https:'
      });

      Cookies.set(
        'timezone-offset',
        String(new Date().getTimezoneOffset() * -1),
        {
          sameSite: 'lax',
          secure: window.location.protocol === 'https:'
        }
      );
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(
        "Failed to determine client's timezone-offset, falling back to 0 (UTC)!"
      );
      // eslint-disable-next-line no-console
      console.error(e);
    }
  }

  // Check app version.
  if (isClient) {
    const currentVersion = localStorage.get<string>('version');
    const currentCommit = localStorage.get<string>('commit');

    if (
      settings.version != currentVersion ||
      settings.commit !== currentCommit
    ) {
      console.warn('New version, deleting localstorage!');
      localStorage.clear();
      localStorage.set('version', settings.version);
      localStorage.set('commit', settings.commit);
    }
  }

  useEffect(() => {
    // Remove server side stylesheets
    const ssrStyles = document.getElementById('server-side-styles');
    if (ssrStyles) {
      ssrStyles.parentNode.removeChild(ssrStyles);
    }

    // SSR History track
    setTimeout(() => {
      store.dispatch(historyPush(router.asPath));
      // Make sure we add delay because of persitent local storage
    }, 500);

    // CSR History track
    router.events.on('routeChangeComplete', url => {
      store.dispatch(historyPush(url));
    });

    setClientSide(true);
  }, []);

  return (
    <Provider store={store}>
      <Emotion.CacheProvider value={emotionCache}>
        <Head key="app">
          <meta
            name="viewport"
            content="initial-scale=1.0, width=device-width"
          />
          {env.apiHost.includes('bad-schwartau') ? (
            <>
              <link rel="icon" href="bad-schwartau-favicon.ico" sizes="any" />
            </>
          ) : (
            <>
              <link rel="icon" href="favicon.ico" sizes="any" />
              <link rel="icon" href="Berlin_de.svg" type="image/svg+xml" />
              <link
                rel="mask-icon"
                href="safari-pinned-tab.svg"
                color="#000000"
              />
              <link rel="apple-touch-icon" href="apple-touch-icon.png" />
              <meta name="og:image" content="Berlin_de_FB_Social.png" />
              <meta
                name="og:image:secure_url"
                content="Berlin_de_FB_Social.png"
              />
            </>
          )}
          <link rel="manifest" href="manifest.json" />
          <meta name="msapplication-TileColor" content="#2b5797" />
          <meta name="msapplication-config" content="browserconfig.xml" />
          <meta name="theme-color" content="#ffffff" />
          {/* {settings.favicon && (
            <link
              rel="icon"
              type="image/ico"
              sizes="16x16"
              href={formatImageUrl(settings.favicon)}
            />
          )} */}
        </Head>
        <ThemeProvider theme={{ ...theme, ...customization }}>
          {/* <PersistGate
          persistor={store.__persistor}
          loading={<LoadingIndicator />}
        > */}
          <IntlProvider
            locale={router.locale}
            defaultLocale="de"
            messages={intlMessages}
          >
            {getLayout(<Component {...pageProps} />)}
          </IntlProvider>
          {/* </PersistGate> */}
        </ThemeProvider>
      </Emotion.CacheProvider>
    </Provider>
  );
}

// https://github.com/vercel/next.js/blob/canary/examples/with-react-intl/helper/loadIntlMessages.ts
async function loadIntlMessages(locale: string) {
  try {
    return import(`../compiled-lang/${locale}.json`).then(
      module => module.default
    );
  } catch (error) {
    throw new Error(
      'Could not load compiled language files. Please run "npm run i18n:compile" first"'
    );
  }
}

function compileMessages(rawMessages) {
  const compiledMessages = {};

  for (const id in rawMessages) {
    if (Object.hasOwnProperty.call(rawMessages, id)) {
      const message = rawMessages[id];
      compiledMessages[id] = parse(message);
    }
  }

  return compiledMessages;
}

RMApp.getInitialProps = wrapper.getInitialAppProps(
  store => async appContext => {
    const {
      ctx: { req, locale }
    } = appContext;

    // Set feature flags
    // @ts-ignore: Unleash Typescript support not available
    if (req?.unleash) {
      // @ts-ignore: Unleash Typescript support not available
      await store.dispatch(setToggles(featureToggles(req.unleash)));
    }

    // Do initial page loading if authenticated
    if (hasSessionCookie(req)) {
      await Promise.all([
        store.dispatch(fetchUser()),
        store.dispatch(initialized())
      ]);
    }

    const organizationRequest = await store.dispatch(fetchOrganizations(null));

    const pageProps = {
      // https://nextjs.org/docs/advanced-features/custom-app#caveats
      ...(await App.getInitialProps(appContext)).pageProps
    };
    const intlMessages = await loadIntlMessages(locale as string);

    const organizationId = Object.keys(
      organizationRequest.payload['organizations']
    )[0];
    const organization =
      organizationRequest.payload['organizations'][organizationId];

    if (
      !!organization.setting_translation_overrides_portal &&
      !!organization.setting_translation_overrides_portal[locale]
    ) {
      const compiledOverrideMessages = compileMessages(
        organization.setting_translation_overrides_portal[locale]
      );
      const keys = Object.keys(intlMessages);

      for (const id of Object.keys(compiledOverrideMessages)) {
        if (keys.includes(id)) {
          intlMessages[id] = compiledOverrideMessages[id];
        }
      }
    }

    return {
      pageProps,
      intlMessages
    };
  }
);

RMApp.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  Component: PropTypes.any.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  pageProps: PropTypes.any,
  // eslint-disable-next-line react/forbid-prop-types
  intlMessages: PropTypes.any
};

RMApp.defaultProps = {
  pageProps: {}
};

export default RMApp;
