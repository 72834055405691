import Router from 'next/router';
import getConfig from 'next/config';

import gkLogo from 'public/gk-logo.png';
import gkFavicon from 'public/favicon.ico';

const env = getConfig()?.publicRuntimeConfig;

const favicon = env.appFavicon ? env.appFavicon : gkFavicon.src;
const logo = env.appLogo ? env.appLogo : gkLogo.src;
const logoPadding = Number(env.appLogoPadding) ? Number(env.appLogoPadding) : 0;
const logoBig = env.appLogoBig ? env.appLogoBig : logo;
const mapHost = env.mapHost ? env.mapHost : 'https://map-gl.groundkeeper.net';
const mapStyle = env.mapStyle
  ? env.mapStyle
  : 'styles/basic-preview/style.json';

const prodEnvNames = ['PROD', 'PRODUCTION', '[PROD]', '[PRODUCTION]'];
const titlePostfix = env.apiHost.includes('bad-schwartau')
  ? 'Bad Schwartau'
  : 'Berlin.de'; // TODO: Make configurable

const Globals = {
  settings: {
    appEnv: env.appEnv,
    version: env.version,
    commit: env.commit,
    logo,
    logoPadding,
    logoBig,
    favicon,
    mapHost,
    mapStyle,
    titlePrefix:
      env.appEnv && !prodEnvNames.includes(env.appEnv.toUpperCase())
        ? `${env.appEnv.toUpperCase()} `
        : '',
    titlePostfix: ` - ${titlePostfix}`
  }
};

export function isServer() {
  return typeof window === 'undefined';
}

export function isClient() {
  return !isServer();
}

export function isTouchDevice() {
  return isClient()
    ? 'ontouchstart' in window ||
        navigator.maxTouchPoints > 0 ||
        navigator.msMaxTouchPoints > 0
    : false;
}

export function redirect(route, res) {
  if (isServer()) {
    res.writeHead(307, { Location: route }).end();
  } else {
    Router.replace(route);
  }
}

export default Globals;
